import classNames from 'classnames'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

const CheckboxGroup = props => {
  const {
    register,
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext() // retrieve all hook methods
  const {
    name = '',
    label = '',
    required = false,
    defaultValue = '',
    className = '',
    rules,
    options = []
  } = props

  useEffect(() => {
    setValue(name, defaultValue)
    if(Boolean(defaultValue)) {
      clearErrors(name)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  return (
    <div className='form-item'>
      <div className='form-item-label'>
        {label && <label className={classNames({ required })}>{label}</label>}
      </div>
      <div className='form-item-control'>
        <div
          className={classNames('form-check', {
            invalid: errors[name]?.message
          })}
        >
          {options.map(option => (
            <div
              className='input-check-item'
              key={name + '-' + option.value}
            >
              <input
                type='checkbox'
                name={name}
                value={option.value}
                className={classNames('input-check', className, {
                  invalid: errors[name]?.message
                })}
                defaultChecked={option.value === defaultValue}
                id={name + '-' + option.value}
                {...register(name, { ...rules })}
              />
              <label htmlFor={name + '-' + option.value}>{option.label}</label>
            </div>
          ))}
        </div>
        {errors[name]?.message && (
          <div className='invalid-msg'>{errors[name]?.message}</div>
        )}
      </div>
    </div>
  )
}

export default CheckboxGroup
