import React from "react";

    const Support = () => {
    return (
    <div className="container vh-100 d-flex justify-content-center align-items-center">
                <div className="bg-light rounded shadow p-4">
                    <div className="text-center mb-4">
                        <p>For any queries or issues, please send to the email below. We will revert back to you at the earliest.</p>
                        <a href="mailto:admin@49ers.trading" className="d-block mt-2 text-primary">
                            admin@49ers.trading
                        </a>
                    </div>
                    <div>
                        <h4 className="mb-3">We follow best security policies:</h4>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <strong>i).</strong> We do not have any functionality to reset password for any users. The only way to reset password is through the <strong>Forgot Password</strong> link, where a reset password link will be sent to the user's email address.
                            </li>
                            <li className="list-group-item">
                                <strong>ii).</strong> We do not have any functionality to update user's personal & bank details, deposit, or withdrawal. Users need to perform the update at their end for any changes.
                            </li>
                            <li className="list-group-item">
                                <strong>iii).</strong> We do not host the database locally. The database is hosted on MongoDB official cloud and we have enabled Change Tracker on it. Any discrepancies can be audited.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        
    );
    };

    export default Support;