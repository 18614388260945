import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'

import options from './reducers/options'
import reports from './reducers/reports'
import tradings from './reducers/tradings'
import transactions from './reducers/transactions'

const reducers = combineReducers({
  options,
  reports,
  tradings,
  transactions
})

const middleware = [thunk]
if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger())
}

const componentEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  reducers,
  componentEnhancers(applyMiddleware(...middleware))
)
const dispatch = store.dispatch

export {
  dispatch
}

export default store