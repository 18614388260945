const initialState = {
  registerAmount: 1999,
  renewAmount: 49,
  agentSignupAmount: 2999,
  goodStandingAmount: 49,
}

const options = (state = initialState, action) => {
  switch (action.type) {
    // case "load": {
    //   return {
    //     ...state,
    //     address: {
    //       items: state.address.items || [],
    //       status: 'error'
    //     }
    //   }
    // }
    default:
      return state
  }
}

export default options
