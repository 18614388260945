import cx from "classnames"
import { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"

const Select = (props) => {
  const { register, formState: { errors }, getValues } = useFormContext() // retrieve all hook methods
  const { name = '', label = '', required = false, placeholder = '', defaultValue = '', className = '', rules, options = [], handleChange } = props

  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
    } else {
      setValue(getValues(name))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, name, errors])

  return (
    <div className="mb-3">
      <label className={cx("block", { required })}>{label}</label>
      <select
        name={name}
        placeholder={placeholder}
        className={cx(className, { 'invalid': errors[name]?.message, 'placeholder-text': !value })}
        defaultValue={defaultValue}
        {...register(name, {
          ...rules,
          onChange: (e) => {
            if(handleChange) {
              handleChange(e)
            }
            setValue(e.target.value)}
        })}
      >
        {placeholder && <option value='' className="placeholder-text">{placeholder}</option>}
        {options.map(option => (
          // selected={option.value === defaultValue}
          <option value={option.value} key={option.value}>{option.label}</option>
        ))}
      </select>
      {errors[name]?.message && <div className="invalid-msg">{errors[name]?.message}</div>}
    </div>
  )
}

export default Select
