import React from 'react'

const Placeholder = ({icon: Icon, text}) => {
  return (
    <div className="p-10">
      <div className="w-full text-center">
        <Icon className="mx-auto inline-block text-9xl text-gray-400" />
        <p className="text-muted text-sm mt-5">{text}</p>
      </div>
    </div>

  )
}

export default Placeholder