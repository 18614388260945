const initialState = {
  reports: {
    status: "loading",
    items: []
  }
}

const reports = (state = initialState, action) => {
  switch (action.type) {
    case "reports": {
      return {
        ...state,
        reports: {
          items: action.items,
          status: action.status
        }
      }
    }
    case "reportsLoading": {
      return {
        ...state,
        reports: {
          status: 'loading'
        }
      }
    }
    case "reportsError": {
      return {
        ...state,
        reports: {
          items: state.reports.items || [],
          status: 'error'
        }
      }
    }
    default:
      return state
  }
}

export default reports
