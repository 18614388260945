import React from "react"
import { Outlet } from "react-router-dom"
import Nav from "./Nav"

const SingleLayout = () => {

  return (
    <div className="min-h-[100vh]">
      <Nav />
      <div className="md:w-10/12 bg-white mt-4 md:mt-10 mx-auto py-8 px-4 md:px-8 flex flex-col justify-center mb-24">
        <Outlet />
      </div>
    </div>
  )
}

export default SingleLayout
