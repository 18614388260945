import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"

const Form = ({
  initialValues,
  onFinish,
  onFinishFailed,
  children,
  reset,
  removeErrorFieldName = ''
}) => {
  const methods = useForm()

  useEffect(() => {
    methods.reset(initialValues)

    return () => {
      methods.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, reset])

  useEffect(()=>{
    if(removeErrorFieldName.length > 0) {
      methods.clearErrors(removeErrorFieldName,null)
    }
  },[removeErrorFieldName])

  const onValid = data => {
    console.log('onValid', data)
    onFinish && onFinish(data)
  }

  const onInvalid = data => {
    console.log('onInvalid', data)
    onFinishFailed && onFinishFailed(data)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onValid, onInvalid)}>
        {children}
      </form>
    </FormProvider>
  )
}

export default Form
