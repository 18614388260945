import { useDispatch } from "react-redux"
import { useNest } from "./useNest"

export function useTradingReports() {
  const { backend } = useNest()
  const dispatch = useDispatch()

  const getReportsDate = (payload) => new Promise((resolve) => {
    let config = {}
    // let config = {
    //   loader: true, msg: {
    //     loading: "Requesting...",
    //     success: "Success"
    //   }
    // }
    const query = new URLSearchParams(payload).toString()
    dispatch({ type: "reportsLoading" })
    backend().get(`/reports/date?${query}`, config)
      .then((data) => {
        dispatch({
          type: "reports",
          items: data.items || [],
          status: "idle"
        })
        return resolve(data)
      }).catch((err) => {
        console.log(err)
        dispatch({ type: "reportsError" })
      })
  })

  const getTradingsDate = (payload) => new Promise((resolve) => {
    let config = {}
    // let config = {
    //   loader: true, msg: {
    //     loading: "Requesting...",
    //     success: "Success"
    //   }
    // }
    const query = new URLSearchParams(payload).toString()
    dispatch({ type: "tradingsLoading" })
    backend().get(`/trading/date?${query}`, config)
      .then((data) => {
        dispatch({
          type: "tradings",
          items: data.items || [],
          status: "idle"
        })
        return resolve(data)
      }).catch((err) => {
        console.log(err)
        dispatch({ type: "tradingsError" })
      })
  })

  const getLastTrading = (payload) => new Promise((resolve) => {
    let config = {}
    // let config = {
    //   loader: true, msg: {
    //     loading: "Requesting...",
    //     success: "Success"
    //   }
    // }
    backend().get(`/trading/lastTrading`, config)
      .then((data) => {
        return resolve(data)
      }).catch((err) => {
        console.log(err)
      })
  })

  const getSpecificUserData = (payload) => new Promise((resolve) => {
    let config = {}
    // let config = {
    //   loader: true, msg: {
    //     loading: "Requesting...",
    //     success: "Success"
    //   }
    // }
    const query = new URLSearchParams(payload).toString()
    dispatch({ type: "tradingsLoading" })
    backend().get(`/trading/userSpecific?${query}`, config)
      .then((data) => {
        dispatch({
          type: "tradings",
          items: data.items || [],
          status: "idle"
        })
        return resolve(data)
      }).catch((err) => {
        dispatch({ type: "tradingsError" })
        console.log(err)
      })
  })

  return {
    getReportsDate,
    getTradingsDate,
    getLastTrading,
    getSpecificUserData
  }
}
