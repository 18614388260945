import React, { useEffect, useRef, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import {
  // FaHeadset,
  // FaDollarSign,
  // FaAddressBook,
  // FaFileInvoiceDollar,
  // FaBuilding,
  // FaUser,
  FaChevronDown
} from "react-icons/fa"
import {
  HiMenu,
  HiMenuAlt3,
} from "react-icons/hi"
import { useClickAway, useWindowSize } from "react-use"
import cx from "classnames"
import logo from "../assets/logo.png"
import { useAuth } from "../context/AuthContext"

const Nav = () => {
  const ref = useRef(null)
  const { logout } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const [dropdown, setDropdown] = useState(false)
  const { width } = useWindowSize()
  const location = useLocation()

  useEffect(() => {
    setIsOpen(false)
  }, [location])

  useEffect(() => {
    if (width > 768 && isOpen) {
      setIsOpen(false)
    }
  }, [width, isOpen])

  console.log(location)
  useEffect(() => {
    if (!isOpen) {
      setIsOpen(false)
    }
  }, [location.pathname, isOpen])


  const handleLogout = () => {
    logout()
      .then(() => {
        window.location.href = "/auth/login"
      })
  }

  useClickAway(ref, () => {
    setDropdown(false)
  })

  return (
    <div className="navigation bg-white shadow-lg">
      <div className={cx("md:w-10/12 mx-auto flex items-center justify-between py-4", { "absolute bg-white top-0 left-0 right-0": isOpen })}>
        <div className="w-28 md:w-48 my-1 flex items-center">
          <Link to="/">
            <img src={logo} className="inline-block" alt="logo" />
            {/* <div className="text-2xl">Holidaays.com</div> */}
          </Link>
        </div>
        <div className="pr-1 md:hidden cursor-pointer">
          {React.createElement(isOpen ? HiMenuAlt3 : HiMenu, {
            className: "text-3xl text-primary-500",
            onClick: () => setIsOpen(!isOpen)
          })
          }
        </div>
        <nav className={cx("absolute bg-white duration-150 md:relative md:block top-20 md:top-auto left-0 right-0",
          { "hidden": !isOpen, "p-8 z-50": isOpen }
        )}>
          <ul className="grid md:grid-flow-col gap-6 md:gap-8 text-center" onClick={() => setIsOpen(false)}>
            <li>
              <Link to="/transactions">
                <span>Transactions</span>
              </Link>
            </li>
            <li>
              <Link to="/deposit">
                <span>Deposit</span>
              </Link>
            </li>
            <li>
              <Link to="/wallet">
                <span>Wallet</span>
              </Link>
            </li>
            <li>
              <Link to="/report">
                <span>Report</span>
              </Link>
            </li>
            <li>
              <Link to="/support">Support</Link>
            </li>
            {!isOpen ?
              <li
                ref={ref}
                className="dropdown"
                onClick={() => { setDropdown(!dropdown) }}
              // onMouseEnter={() => { dropdown === false && setDropdown(!dropdown) }}
              // onMouseOut={() => { dropdown === true && setDropdown(false) }}
              >
                <Link to="#">
                  <span>Account</span> <FaChevronDown className="inline text-xs" />
                </Link>
                {dropdown &&
                  <ul className="dropdown-menu" onClick={() => setIsOpen(false)}>
                    <li>
                      <Link to="/profile">
                        Personal Details
                      </Link>
                    </li>
                    <li>
                      <Link to="/account/password">Change Password</Link>
                    </li>
                    <li>
                      <Link to="#" onClick={handleLogout}>
                        Logout
                      </Link>
                    </li>
                  </ul>
                }
              </li>
              : <>
                <li>
                  <Link to="/profile">
                    Personal Details
                  </Link>
                </li>
                <li>
                  <Link to="/account/password">Change Password</Link>
                </li>
                <li>
                  <Link to="#" onClick={handleLogout}>
                    Logout
                  </Link>
                </li>
              </>
            }
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Nav
