import React from "react"
import banner from "../../assets/banner.gif"

const Home = () => {
  return (
    <div>
      <h3 className="text-xl md:text-3xl text-primary-500 text-center">Gold Mining &amp; Trading are analogous</h3>
      {/* <h3 className="text-xl text-primary-700 my-4">...they have same meaning to accumulate wealth hunt for wealth</h3> */}
      <img src={banner} alt="banner" className="md:w-2/4 mx-auto mt-6" />
    </div>
  )
}

export default Home
