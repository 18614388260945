import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux"
import { usePrice } from "../hooks/usePrice";
import { useTransactions } from "../nest/useTransactions";
import { useAuth } from "../context/AuthContext";
// import { useTradingReports } from '../nest/useTradingReports'
import {
  calculatedTotalWithdrawalAmount,
  percentageOfAmount,
  // calculateProfitLossShare
} from "../utils/calculations";
import { Form, Input, Select, Submit } from "../tui";
import { toast } from "react-toastify";
import { useEvent } from "react-use";
import ConfirmationModal from "../components/ConfirmationModal";
import { LoaderTextBlock } from "../utils/loader";

const Wallet = () => {
  // const [txnId, setTxnId] = useState()
  // const [item, setItem] = useState({})
  // const [netAmountPercentage, setNetAmountPercentage] = useState(0)
  // const [profitLossPer, setProfitLossPer] = useState(0)
  // const [balance, setBalance] = useState(0)
  const [withdrawalLabels, setWithdrawalLabels] = useState([]);
  const [totalAmount, setTotalAmount] = useState();
  const [withdrawalAmount, setWithdrawalAmount] = useState();
  const [openConfirmationModal, setConfirmationModal] = useState(false);
  const [extraCharges, setExtraCharges] = useState({
    withdrawalFees: 0,
    AED: 0,
  });
  const [totalPendingDeposite, setTotalPendingDeposit] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { price } = usePrice();
  const { user, updateBalance, getUserProfile } = useAuth();

  // const { transactions } = useSelector(state => state.transactions)
  const { makeWithdrawal, getOptions, getAllDeposits } = useTransactions();
  // const { getLastTrading } = useTradingReports()

  const windowHasFocus = () => {
    getUserProfile();
  };
  useEvent("focus", windowHasFocus, window, { capture: true });

  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    const tmpWithdrawalLabels = [
      { value: 100, label: "100 % of current Amount" },
      { value: 50, label: "50 % of current Amount" },
      { value: 25, label: "25 % of current Amount" },
    ];
    tmpWithdrawalLabels.map((item) => {
      const value = percentageOfAmount(user.currentAmount, item.value);
      item.value = value;
      item.label = item.label + " " + price(value);
      return {};
    });

    // if balance is more than deposit it means profit
    if (user.currentAmount > user.deposit) {
      const profit = price(user.currentAmount - user.deposit);
      tmpWithdrawalLabels.push({
        value: user.currentAmount - user.deposit,
        label: "Only profit of current Amount " + profit,
      });
      // tmpWithdrawalLabels.reverse()
    }
    setWithdrawalLabels(
      user.currentAmount > 0 ? tmpWithdrawalLabels.reverse() : []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.currentAmount]);

  useEffect(() => {
    getOptions().then((item) => {
      const options = item.items;
      const withdrawalFee = options.find(
        (op) => op.name === "withdrawalFees"
      ).value;
      const AEDCharge = options.find((op) => op.name === "AED").value;
      setExtraCharges({
        withdrawalFees: withdrawalFee,
        AED: AEDCharge,
      });
      setIsLoading(false);
    });
    getAllDeposits().then((item) => {
      const totalPendingDeposit =
        item?.items?.length > 0
          ? item.items.reduce((total, current) => total + current.amount, 0)
          : 0;
      setTotalPendingDeposit(totalPendingDeposit);
    });
  }, []);

  // useEffect(() => {
  //   getLastTrading().then(({ item }) => {
  //     // setItem(items[0])
  //     // setNetAmountPercentage(items[0].netAmountPercentage)
  //     // const tmpProfitShare = calculateProfitShare(10, 50)
  //     const tmpProfitShare = calculateProfitLossShare(item.netAmountPercentage, user.profitShare, user.lossShare)
  //     setProfitLossPer(tmpProfitShare)
  //   // const amount = amountByPercentage(user.deposit, tmpProfitShare)
  //   // console.log(items[0].netAmountPercentage)
  //   // setBalance(amount)
  //   // console.log(items)
  //   })
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user.deposit])

  const handleChange = (e) => {
    const withdrawalValue = e.target.value;
    setWithdrawalAmount(withdrawalValue);
    const total = calculatedTotalWithdrawalAmount(
      withdrawalValue,
      extraCharges.AED,
      extraCharges.withdrawalFees
    );
    setTotalAmount(total ? total : "");
  };

  const onSubmit = (data) => {
    console.log("data", data);
    // if (parseFloat(data.withdrawalAmount) <= user.currentAmount) {
    // if (parseFloat(totalAmount) <= user.currentAmount) {
    //   makeWithdrawal({withdrawalAmount: totalAmount}).then(resData => {
    //     console.log(resData)
    //     updateBalance(resData)
    //   })
    // } else {
    //   toast.error('Not enough balance')
    // }
    setConfirmationModal(true);
  };
  const handleConfirmTransaction = () => {
    const totalPendingAmount =
      user.currentAmount - user.totalPendingWithdrawalAmount;
    if (parseFloat(withdrawalAmount) <= totalPendingAmount) {
      makeWithdrawal({ withdrawalAmount: withdrawalAmount }).then((resData) => {
        console.log(resData);
        getUserProfile();
        // updateBalance(resData)
        setConfirmationModal(false);
        setTotalAmount(0);
      });
    } else {
      toast.error("Not enough balance");
      setConfirmationModal(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoaderTextBlock />
      ) : (
        <Form onFinish={onSubmit}>
          <div>
            <h1 className="text-xl md:text-3xl mb-4">Wallet</h1>
            {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}
            <div className="mt-6">
              <div className="mx-auto w-full md:w-10/12">
                <div className="mx-auto grid md:grid-cols-1 text-center space-y-6 md:space-x-8 md:space-y-0 mb-8">
                  <div className="p-8 bg-white rounded-lg border border-gray-200 shadow-lg">
                    <h6 className="mb-4 text-2xl font-extrabold tracking-tight text-gray-900">
                      Trading Pool:{" "}
                      <span className="text-green-500">
                        {user.tradingPool}
                      </span>
                    </h6>
                    <h6 className="mb-4 text-2xl font-extrabold tracking-tight text-gray-900">
                      Profit Share:{" "}
                      <span className="text-green-500">
                        {user.profitShare}%
                      </span>
                    </h6>
                    <h6 className="text-2xl font-extrabold tracking-tight text-gray-900">
                      Loss Share:{" "}
                      <span className="text-red-500">
                        {user.lossShare}%
                      </span>
                    </h6>
                  </div>
                </div>

                <div className="mx-auto grid md:grid-cols-3 text-center space-y-4 md:space-x-8 md:space-y-0">
                  <div className="gap-3" style={{ display: "grid" }}>
                    <div className="p-6 bg-blue-200 text-blue-800 rounded-lg border border-gray-200 shadow-md">
                      <h5 className="mb-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
                        {price(user.deposit)}
                      </h5>
                      <p className="mb-3 font-normal">Total Deposited Amount</p>
                      <small className="text-gray-500">
                        Total Amount deposited by you until Now [ Reference
                        Purpose Data]
                      </small>
                    </div>
                    <div className="p-6 bg-yellow-200 text-yellow-800 rounded-lg border border-gray-200 shadow-md">
                      <h5 className="mb-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
                        {price(totalPendingDeposite)}
                      </h5>
                      <p className="mb-3 font-normal">Pending Deposit</p>
                      <small className="text-gray-500">
                        Amount is in Pending state , Once Received , It will be
                        Added into "Total Deposited Amount" and "Current Amount"
                      </small>
                    </div>
                  </div>
                  <div className="p-6 bg-white text-purple-800 rounded-lg border border-gray-200 shadow-md flex flex-col justify-center">
                    <div className="">
                      <h5 className="mb-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
                        {price(user.currentAmount)}
                      </h5>
                      <p className="mb-3 font-normal">Current Amount</p>
                      <small className="text-gray-500">
                        [Your Confirmed Deposits - Confirmed Withdrawal Amount ]
                        +- Previous Day Profit/Loss Amount
                      </small>
                    </div>
                  </div>
                  <div className="gap-3" style={{ display: "grid" }}>
                    <div className="p-6 bg-blue-200 text-red-800 rounded-lg border border-gray-200 shadow-md">
                      <h5 className="mb-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
                        {price(user.totalWithdrawalAmount)}
                      </h5>
                      <p className="mb-3 font-normal">
                        Total Withdrawal Amount
                      </p>
                      <small className="text-gray-500">
                        Total Withdrawal made by you until Now [ Reference
                        Purpose Data ]
                      </small>
                    </div>
                    <div className="p-6 bg-yellow-200 text-green-800 rounded-lg border border-gray-200 shadow-md">
                      <h5 className="mb-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
                        {price(user.totalPendingWithdrawalAmount)}
                      </h5>
                      <p className="mb-3 font-normal">Pending Withdrawal</p>
                      <small className="text-gray-500">
                        Amount is in Pending state , Once Confirmed , It will be
                        minus from "Current Amount"
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
              {/* <pre>{JSON.stringify(withdrawalLabels, null, 2)}</pre> */}
              <div className="mt-4">
                <div className="mx-auto md:w-10/12">
                  {user.currentAmount > 1 &&
                  user.currentAmount > user.totalPendingWithdrawalAmount ? (
                    <div className="w-full inline-block align-middle">
                      <Select
                        name="withdrawalAmount"
                        placeholder="Select Withdrawal Amount Percentage"
                        options={withdrawalLabels}
                        rules={{
                          required: "Withdrawal Amount Percentage is required",
                        }}
                        handleChange={handleChange}
                      />
                      <Input.Email
                        name="withdrawal_fees"
                        defaultValue={`Withdrawal Fees: ${extraCharges.withdrawalFees}`}
                        className="p-3"
                        min={10}
                        disabled
                      />
                      <Input.Email
                        name="usd_to_aed"
                        defaultValue={`USD to AED rate: ${extraCharges.AED}`}
                        className="p-3"
                        min={10}
                        disabled
                      />
                      <Input.Email
                        label="Your final withdrawal amount"
                        name="total_amt"
                        defaultValue={`AED ${totalAmount ? totalAmount : ""}`}
                        className="p-3"
                        min={10}
                        disabled
                      />
                      <div className="flex justify-between mt-5">
                        <Submit className="btn btn-primary w-full">
                          Withdrawal
                        </Submit>
                      </div>
                    </div>
                  ) : (
                    <div className="w-full inline-block text-center mt-8 text-2xl">
                      You do not have Fund to Withdraw
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
      {openConfirmationModal && (
        <ConfirmationModal
          open={openConfirmationModal}
          handleClose={() => setConfirmationModal(false)}
          onCLickConfirm={handleConfirmTransaction}
          status="Confirm"
        />
      )}
    </>
  );
};

export default Wallet;
