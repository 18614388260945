const initialState = {
  tradings: {
    status: "loading",
    items: []
  }
}

const tradings = (state = initialState, action) => {
  switch (action.type) {
    case "tradings": {
      return {
        ...state,
        tradings: {
          items: action.items,
          status: action.status
        }
      }
    }
    case "tradingsLoading": {
      return {
        ...state,
        tradings: {
          status: 'loading'
        }
      }
    }
    case "tradingsError": {
      return {
        ...state,
        tradings: {
          items: state.tradings.items || [],
          status: 'error'
        }
      }
    }
    default:
      return state
  }
}

export default tradings
