import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { Form, Input, Submit } from '../../tui'
import TnCModal from '../../components/TnCModal'
import CheckboxGroup from '../../tui/Form/CheckboxGroup'

const Signup = () => {
  const { signup } = useAuth()
  const navigate = useNavigate()
  const [password, setPassword] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [isAccepted, setIsAccepted] = useState('')

  const onSubmit = data => {
    console.log('data', data)
    const profile = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobile: data.mobile
    }
    signup(data.email, data.password, profile).then(() => {
      navigate('/')
    })
  }

  const onConfirm = (v) => {
    console.log('onConfirm', v)
    setIsOpen(false)
    setIsAccepted('Y')
  }

  const onCancel = (v) => {
    console.log('onCancel', v)
    setIsOpen(false)
    setIsAccepted('')
  }

  return (
    <div className='md:w-1/3 bg-auth mx-auto p-8 flex flex-col justify-center'>
      <div className='text-center'>
        <h2 className='text-4xl mb-3'>Signup</h2>
        <p className='text-muted text-sm'>Create your account.</p>
      </div>
      <Form onFinish={onSubmit}>
        <div className=''>
          <Input.Text
            required
            name='firstName'
            label='First Name'
            placeholder='First Name'
            rules={{ required: 'First Name is required' }}
          />
          <Input.Text
            required
            name='lastName'
            label='Last Name'
            placeholder='Last Name'
            rules={{ required: 'Last Name is required' }}
          />
          <Input.Text
            required
            name='mobile'
            label='Contact No'
            placeholder='Contact No'
            rules={{ required: 'Contact No is required' }}
          />
          <Input.Email
            required
            name='email'
            label='Email'
            placeholder='Email'
            rules={{ required: 'Email is required' }}
          />
          <Input.Password
            required
            name='password'
            label='Password'
            placeholder='Password'
            rules={{
              required: 'Password is required',
              onChange: e => setPassword(e.target.value)
            }}
          />
          <Input.Password
            required
            name='password-conf'
            label='Confirm Password'
            placeholder='Confirm Password'
            rules={{
              required: 'Confirm Password is required',
              validate: value =>
                value === password ||
                'Password and Confirm password does not matched.'
            }}
          />
          <CheckboxGroup
            required
            name='tnc'
            label=''
            defaultValue={isAccepted}
            options={[
              {
                value: 'Y',
                label: (
                  <button
                    className='underline cursor-pointer'
                    onClick={() => setIsOpen(!isOpen)}
                    type={"button"}
                    tabIndex={-1}
                  >
                    Terms and Conditions
                  </button>
                )
              }
            ]}
            rules={{ required: 'Please Accept terms and conditions' }}
          />
          <div className='flex justify-between items-center mt-5'></div>
          <div className='flex justify-between items-center mt-5'>
            <Submit className='btn btn-primary py-2'>Signup</Submit>
            <span>
              Have an Account? <Link to='/auth/login'>Login</Link>
            </span>
          </div>
        </div>
      </Form>
      <TnCModal onConfirm={onConfirm} onCancel={onCancel} isOpen={isOpen} />
    </div>
  )
}

export default Signup
