import React from "react"

import logo from "../assets/logo.png"
import { BiError } from "react-icons/bi"
import { Link } from "react-router-dom"

const Page404 = () => {
  return (
    <div className="flex flex-col justify-center min-h-[100vh]">
      <div className="">
        <div className="w-36 h-24 mx-auto mb-8">
          <Link to="/">
            <img src={logo} className="inline-block" alt="logo" />
          </Link>
        </div>
        <div className="w-max mx-auto p-8 flex flex-col justify-center text-center">
          <BiError className='text- text-8xl m-auto mb-4' />
          <div className="text-5xl mb-4 text-muted">
            <h2>Error 404</h2>
          </div>
          <p>Page not found!</p>
          <Link className='mt-8' to='/'>
            Back To Home
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Page404
