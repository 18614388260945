import axios from 'axios'
import { toast } from "react-toastify"
import { showLoader } from "../utils/loader"
import { useCookie, useLocalStorage } from "react-use"
import { config } from "../config"

const baseURL = config.apiUrl
const tokenName = process.env.REACT_APP_TOKEN_NAME || 'authToken'

export const useNest = () => {
  const [tokenC, updateTokenC, deleteTokenC] = useCookie(tokenName)
  const [tokenL, updateTokenL, deleteTokenL] = useLocalStorage(tokenName)

  const backend = (needLoader = false) => {
    const nest = axios.create({ baseURL: baseURL })
    try {
      // Add a request interceptor
      nest.interceptors.request.use(function (config) {
        // Do something before request is sent
        if (config.loader) {
          // if (needLoader) {
          showLoader(true)
          // window.showLoader = true
        }
        if (config.msg) {
          if (config.msg.loading) {
            config.msg.id = toast.loading(config.msg.loading)
          }
        }
        if (getToken()) {
          config.headers = { Authorization: `Bearer ${getToken()}` }
        }
        return config
      }, function (error) {
        // Do something with request error
        return Promise.reject(error)
      })

      // Add a response interceptor
      nest.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        if (response.config.msg) {
          let message = response?.config?.msg?.success || response?.data?.message
          if (response?.config?.msg?.id) {
            toast.update(response.config.msg.id, { render: message, type: "success", isLoading: false, autoClose: true })
          } else {
            toast.success(message)
          }
        }
        // if (response?.data?.token) {
        //   storeToken(response?.data?.token)
        // }

        if (response?.config?.loader) {
          // if (needLoader) {
          showLoader(false)
          // window.showLoader = false
        }
        return response?.data || response
      }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        let message = error?.response?.data?.message?.message || error?.response?.data?.message || error?.response?.statusText || 'Network error'
        if (error?.response?.config?.msg) {
          if (error?.response?.config?.msg?.id) {
            toast.update(error.response.config.msg.id, { render: message, type: "error", isLoading: false, autoClose: true })
          } else {
            toast.error(message)
          }
        } else if (error?.config?.msg) {
          if (error?.config?.msg?.id) {
            toast.update(error.config.msg.id, { render: message, type: "error", isLoading: false, autoClose: true })
          } else {
            toast.error(message)
          }
        }
        // if (error?.response?.config?.loader) {
        // if (needLoader) {
        // showLoader(false)
        // window.showLoader = false
        // }
        if (error?.response?.status === 401 && window.location.href.search('auth') === -1) {
          window.location.href = '/auth/login'
        }
        showLoader(false)
        return Promise.reject(error)
      })
    } catch (error) {
      toast.error(error)
      console.log(error)
    }
    return nest
  }

  const updateToken = (token, remember) => {
    updateTokenL(token)
    remember === true && updateTokenC(token)
  }

  const removeToken = (token) => {
    deleteTokenL(token)
    deleteTokenC(token)
  }

  const getToken = () => {
    return tokenL || tokenC || null
  }

  return {
    backend,
    getToken,
    updateToken,
    removeToken,
    baseURL
  }
}
