import React, { Fragment, useEffect, useRef, useState } from 'react'
import { FaExclamationTriangle } from 'react-icons/fa'
import { Dialog, Transition } from '@headlessui/react'

const TnCModal = ({ onCancel, onConfirm, isOpen = false }) => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  const onClose = () => {
    setOpen(false)
    if (onCancel) {
      onCancel(false)
    }
  }

  const onOk = () => {
    setOpen(false)
    if (onConfirm) {
      onConfirm(true)
    }
  }
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full'>
                <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                  <div className='sm:flex sm:items-start'>
                    <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10'>
                      <FaExclamationTriangle
                        className='h-6 w-6 text-primary-500'
                        aria-hidden='true'
                      />
                    </div>
                    <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                      <Dialog.Title
                        as='h3'
                        className='text-lg leading-6 font-medium text-gray-900'
                      >
                        Terms & Conditions
                      </Dialog.Title>
                      <div className='mt-2 text-sm italic'>
                        <p className='py-1'>
                          Please read below Terms & Conditions & after agreeing
                          to it, you will be able sign up with us:
                        </p>
                        <ol className='py-1'>
                          <li>
                            <b>i.</b> Trading is very high Risk activity, Many
                            statistics shows that more than 80% people lose
                            money. If you're not affording to lose money please
                            do not proceed.
                          </li>
                          <li>
                            <b>ii.</b> As per our policy, we have set the rule that any
                            point of time, your maximum deposit amount
                            [excluding the profit amount accumulation] with us
                            should not be exceeding your two months’ salary or
                            income.
                          </li>
                          <li>
                          <b>iii.</b> Currently our company is not legally registered
                            and under evaluation/Testing phase. All your
                            investment is through personal relationship based on
                            trust on each other. At the same time we promise you
                            that we will throughout maintain transparent, fair &
                            honest attitude.
                          </li>
                        </ol>
                        <p>
                          Once our evaluation/Testing phase is complete and
                          generates a sufficient income, we will register our
                          company ASAP and we will notify you for the same.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse gap-2'>
                  <button
                    type='button'
                    className='btn btn-primary'
                    onClick={onOk}
                  >
                    Agree
                  </button>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    onClick={onClose}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default TnCModal
