import { useEffect } from "react"
import {
  Routes,
  Route,
  useLocation,
  // Navigate
} from "react-router-dom"
import PrivateOutlet from "./PrivateOutlet"

import AuthLayout from "../Layout/Auth"
import Login from "../Pages/Auth/Login"
import Signup from "../Pages/Auth/Signup"
import Forgot from "../Pages/Auth/PassForgot"
import Reset from "../Pages/Auth/PassReset"
import UpdatePass from "../Pages/Auth/UpdatePass"
// import SmallLayout from "../Layout/SmallLayout"

import Page404 from "../Pages/404"
import PagePending from "../Pages/Pending"

import SingleLayout from "../Layout/SingleLayout"
import Home from "../Pages/Home"
import Report from "../Pages/Report"
import Deposit from "../Pages/Deposit"
import Wallet from "../Pages/Wallet"
import Transactions from "../Pages/Transactions"

// import Home from "../Pages/Home"
import AccountSetting from "../Pages/AccountSetting"
import Support from "../Pages/Support"

const AllRoutes = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [location])

  return (
    <Routes>
      <Route path="/auth" element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="forgot" element={<Forgot />} />
        <Route path="reset/:code" element={<Reset />} />
      </Route>
      {/* NOTE: Private routes start here */}
      <Route path="/pending" element={<PagePending />} />
      <Route path="/" element={<PrivateOutlet />}>
        <Route path="/account/password" element={<AuthLayout />}>
          <Route index element={<UpdatePass />} />
        </Route>
        <Route path="/" element={<SingleLayout />}>
          <Route index element={<Home />} />
          <Route path="/profile" element={<AccountSetting />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/support" element={<Support />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/report" element={<Report />} />
        </Route>
      </Route>
      <Route
        path="*"
        element={<Page404 />}
      />
    </Routes>
  )
}

export default AllRoutes
