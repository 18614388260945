import React from 'react'
import {
  Outlet,
  Navigate
} from 'react-router-dom'
import { useAuth } from "../context/AuthContext"
import { LoaderSuspense } from "../utils/loader"

export default function PrivateOutlet() {
  const { logged, status, user } = useAuth()

  // return <Outlet />
  return (
    logged ?
    user.status === 'Pending' ? <Navigate to="/pending" /> :
    <Outlet /> : status === "loading" ? <LoaderSuspense /> : (
      <Navigate to="/auth/login" />
    )
  )
}
