import { useDispatch } from "react-redux";
import { useNest } from "./useNest";

export function useTransactions() {
  const { backend } = useNest();
  const dispatch = useDispatch();

  const makeDeposit = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: {
          loading: "Requesting...",
          success: "Amount will be available after approved by admin. ",
        },
      };
      backend()
        .post(`/transactions`, { ...payload, type: "Deposit" }, config)
        .then(({ item }) => {
          dispatch({
            type: "transactionAdd",
            item: item,
          });
          return resolve(item);
        });
    });

  const makeWithdrawal = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: {
          loading: "Requesting...",
          success:
            "Withdrawal Request submitted. You will Receive money in 2-5 working Days'",
        },
      };
      backend()
        .post(`/transactions/withdrawal`, { ...payload }, config)
        .then((data) => {
          dispatch({
            type: "transactionAdd",
            item: data.item,
          });
          return resolve(data);
        });
    });

  const myTransactions = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: false,
        msg: false,
      };
      const query = new URLSearchParams(payload).toString();
      dispatch({ type: "transactionsLoading" });
      backend()
        .get(`/transactions/my?${query}`, config)
        .then((data) => {
          dispatch({
            type: "transactions",
            items: data.items || [],
            status: "idle",
          });
          return resolve(data);
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: "transactionsError" });
        });
    });

  const removeMyTransaction = (id, cb) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: {
          loading: "Requesting...",
          success: "Transaction Request is deleted successfully",
        },
      };
      // dispatch({ type: "transactionsLoading" });
      backend()
        .put(
          `/transactions/status/${id}`,
          { status: "Cancelled", reasonForRejection: "" },
          config
        )
        .then((data) => {
          dispatch({
            type: "transactionRemove",
            id: id,
          });
          cb();
          return resolve(data);
        })
        .catch((err) => {
          console.log(err);
          cb();
          dispatch({ type: "transactionsError" });
        });
    });

  const getOptions = () =>
    new Promise((resolve) => {
      let config = {
        loader: false,
        msg: false,
      };

      backend()
        .get(`/options`, config)
        .then((data) => {
          return resolve(data);
        })
        .catch((err) => {
          console.log(err);
          // dispatch({ type: "transactionsError" })
        });
    });
  const getAllDeposits = () =>
    new Promise((resolve) => {
      let config = {
        loader: false,
        msg: false,
      };

      backend()
        .get(`/transactions/getAllDeposits`, config)
        .then((data) => {
          return resolve(data);
        })
        .catch((err) => {
          console.log(err);
          // dispatch({ type: "transactionsError" })
        });
    });

  return {
    makeDeposit,
    makeWithdrawal,
    myTransactions,
    removeMyTransaction,
    getOptions,
    getAllDeposits,
  };
}
