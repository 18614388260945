import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FaCaretDown, FaCaretUp, FaFileAlt, FaListAlt, FaExternalLinkAlt } from 'react-icons/fa'
import dayjs from 'dayjs'
import DatePicker from 'react-datepicker'
import { Form, Submit } from '../tui'

import { LoaderTextBlock } from '../utils/loader'
import Placeholder from '../components/Placeholder'
import { useTradingReports } from '../nest/useTradingReports'
import { config } from '../config'
// import Badge from "../../tui/Components/Badge"

const Report = () => {
  var date = new Date()
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  const { reports } = useSelector(state => state.reports)
  const { tradings } = useSelector(state => state.tradings)
  const [startDate, setStartDate] = useState(firstDay)
  const [endDate, setEndDate] = useState(new Date())
  const { getReportsDate, getTradingsDate, getSpecificUserData } = useTradingReports()

  useEffect(() => {
    // getTradingsDate({ limit: 100, startDate: startDate, endDate: endDate })
    getReportsDate({ limit: 100, startDate: startDate, endDate: endDate })
    getSpecificUserData({ limit: 100, startDate: startDate, endDate: endDate })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = data => {
    console.log('data', data)
    // getTradingsDate({ limit: 100, startDate: startDate, endDate: endDate })
    getSpecificUserData({ limit: 100, startDate: startDate, endDate: endDate })
    getReportsDate({ limit: 100, startDate: startDate, endDate: endDate })
  }

  return (
    <div>
      <h1 className='text-xl md:text-3xl mb-4'>Reports</h1>
      <Form onFinish={onSubmit}>
        <div className='md:flex justify-between'>
          <div className='flex space-x-4 mb-2'>
            <div className='w-full'>
              <div className='mb-1'>
                <label>From Date</label>
                <DatePicker
                  name='startDate'
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  selectsStart
                  dateFormat='dd-MM-yyyy'
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
            </div>
            <div className='w-full'>
              <div className='mb-1'>
                <label>To Date</label>
                <DatePicker
                  name='endDate'
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  selectsEnd
                  dateFormat='dd-MM-yyyy'
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                />
              </div>
            </div>
          </div>
          <div className='flex md:items-end'>
            <Submit className='btn btn-primary w-full'>Submit</Submit>
            {/* {filtered &&
              <button type="reset" className="flex items-center bg-input p-2 px-4" onClick={clearFilter}>
                <HiRefresh className="mr-2" />
                Clear Filter
              </button>
            } */}
          </div>
        </div>
      </Form>
      <div className='mt-6'>
        <h2 className='mb-2 text-xl'>Profit/Loss Summary</h2>
        <div className='flex flex-col'>
          <div className='overflow-x-auto'>
            <div className='w-full inline-block align-middle'>
              <div className='border rounded-lg'>
                {tradings.status === 'loading' ? (
                  <LoaderTextBlock />
                ) : tradings?.items?.length === 0 ? (
                  <Placeholder icon={FaListAlt} text={'No summary found'} />
                ) : (
                  <table className='min-w-full divide-y divide-gray-200'>
                    <thead className='bg-gray-50'>
                      <tr>
                        <th
                          scope='col'
                          className='px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase'
                        >
                          Date
                        </th>
                        <th
                          scope='col'
                          className='px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase'
                        >
                          Trading Pool
                        </th>
                        <th
                          scope='col'
                          className='px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase'
                        >
                          Profit/Loss
                        </th>
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200'>
                      {tradings.items.map(item => (
                        <tr key={item.id}>
                          <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                            {dayjs(item.createdAt).format('MMM DD, YYYY')}
                          </td>
                          <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                            {item.tradingPool}
                          </td>
                          <td className='px-6 py-4 text-sm text-right text-gray-800 whitespace-nowrap'>
                            {/* {price(item.netAmount)} */}
                            <b>{item.netAmountPercentage}%</b>
                            {item.closingType === 'Profit' ? (
                              <FaCaretUp className='text-success' />
                            ) : (
                              <FaCaretDown className='text-danger' />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-6'>
        <h2 className='mb-2 text-xl'>Exchange Report File</h2>
        <div className='flex flex-col'>
          <div className='overflow-x-auto'>
            <div className='w-full inline-block align-middle'>
              <div className='border rounded-lg'>
                {reports.status === 'loading' ? (
                  <LoaderTextBlock />
                ) : reports?.items?.length === 0 ? (
                  <Placeholder icon={FaFileAlt} text={'No reports found'} />
                ) : (
                  <table className='min-w-full divide-y divide-gray-200'>
                    <thead className='bg-gray-50'>
                      <tr>
                        <th
                          scope='col'
                          className='px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase'
                        >
                          Date
                        </th>
                        <th
                          scope='col'
                          className='px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase'
                        >
                          Asset
                        </th>
                        <th
                          scope='col'
                          className='px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase'
                        >
                          Exchange Name
                        </th>
                        <th
                          scope='col'
                          className='px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase'
                        >
                          Trading Pool
                        </th>
                        <th
                          scope='col'
                          className='px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase'
                        >
                          View
                        </th>
                        <th
                          scope='col'
                          className='px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase'
                        >
                          Download
                        </th>
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200'>
                      {reports.items.map(item => (
                        <tr key={item.id}>
                          <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                            {dayjs(item.createdAt).format('MMM DD, YYYY')}
                          </td>
                          <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                            {item.asset}
                          </td>
                          <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                            {item.exchange}
                          </td>
                          <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                            {item.tradingPool}
                          </td>
                          <td className='px-6 py-4 text-sm font-medium text-right whitespace-nowrap'>
                          {item?.link ? <a href={item?.link} target={"_blank"} rel="noreferrer"><FaExternalLinkAlt /></a> : '--'}
                            {/* {item?.file?.mimetype === 'application/pdf' && (
                              <a
                                href={`${config.apiUrl}/reports/view/${item.id}`}
                                target='_blank'
                                rel='noreferrer'
                              >
                                View
                              </a>
                            )} */}
                          </td>
                          <td className='px-6 py-4 text-sm font-medium text-right whitespace-nowrap'>
                          {item?.file?.filename && ( <a
                              href={`${config.apiUrl}/reports/download/${item.id}`}
                            >
                              Download
                            </a>)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Report
