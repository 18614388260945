import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaSearchDollar } from "react-icons/fa";
import dayjs from "dayjs";
import cx from "classnames";
import { LoaderTextBlock } from "../utils/loader";
import { getBgColor } from "../utils/label";
import { usePrice } from "../hooks/usePrice";
import Placeholder from "../components/Placeholder";
// import Badge from "../../tui/Components/Badge"
import { useTransactions } from "../nest/useTransactions";
import { formatTxnNumber } from "../utils/calculations";
import ConfirmationModal from "../components/ConfirmationModal";
import { MdDelete } from "react-icons/md"

const Transactions = () => {
  const { price } = usePrice();
  const { transactions } = useSelector((state) => state.transactions);
  const { myTransactions, removeMyTransaction } = useTransactions();
  const [openConfirmationModal, setConfirmationModal] = useState({
    show: false,
    id: "",
  });

  useEffect(() => {
    // myTransactions({ limit: 100, type: 'Deposit' }).then(() => setDeposit(true))
    myTransactions({ limit: 100 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelItem = () => {
    setConfirmationModal({ show: false, id: "" });
  };

  const handleRemoveTransaction = () => {
    removeMyTransaction(openConfirmationModal.id, ()=> setConfirmationModal({ show: false, id: "" }))
  };

  return (
    <div>
      <h1 className="text-xl md:text-3xl mb-4">Transactions</h1>
      <div className="mt-6">
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="w-full inline-block align-middle">
              <div className="border rounded-lg">
                {transactions.status === "loading" ? (
                  <LoaderTextBlock />
                ) : transactions?.items?.length === 0 ? (
                  <Placeholder
                    icon={FaSearchDollar}
                    text={"No transactions found"}
                  />
                ) : (
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          Transaction ID
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          Type
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-gray-500 uppercase text-right"
                        >
                          Amount
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          Action
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                        >
                          Date
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {transactions.items.map((item) => (
                        <tr key={item.id}>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {formatTxnNumber(item.txnId)}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {item.type}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap text-right">
                            {price(item.amount, item.currency)}
                          </td>
                          <td className={`px-6 text-sm text-gray-800 whitespace-nowrap ${item.status === "Rejected" ? 'py-2 mb-0.5' : 'py-4'}`}>
                            <span
                              className={cx(
                                "text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold text-white rounded",
                                getBgColor(item.status)
                              )}
                            >
                              {item.status}
                            </span>
                            {item.status === "Rejected" &&<> <br/> <span>{item.reasonForRejection}</span></>}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {item.status === "Pending" && (
                              <span
                                onClick={() =>
                                  setConfirmationModal({
                                    show: true,
                                    id: item.id,
                                  })
                                }
                                className="text-s inline-block cursor-pointer py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold text-white rounded bg-danger"
                              >
                                <MdDelete />
                              </span>
                            )}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                            {dayjs(item.createdAt).format("MMM DD, YYYY")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        open={openConfirmationModal.show}
        handleClose={handleCancelItem}
        onCLickConfirm={handleRemoveTransaction}
      />
    </div>
  );
};

export default Transactions;
