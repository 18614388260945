import React from "react"
import { Link } from "react-router-dom"

import { BiError } from "react-icons/bi"
import logo from "../assets/logo.png"
import { useAuth } from "../context/AuthContext"

const PagePending = () => {
  const { logout, user } = useAuth()
  const handleLogout = () => {
    logout()
      .then(() => {
        window.location.href = "/auth/login"
      })
  }

  return (
    <div className="flex flex-col justify-center min-h-[100vh]">
      <div className="">
        <div className="w-36 h-24 mx-auto mb-8">
          <Link to="/">
            <img src={logo} className="inline-block" alt="logo" />
          </Link>
        </div>
        <div className="w-max mx-auto p-8 flex flex-col justify-center text-center">
          <BiError className='text-red-400 text-8xl m-auto mb-4' />
          <div className="text-5xl mb-4 text-muted">
            <h2>Pending Account</h2>
          </div>
          <p className="my-4">{user.email}</p>
          <p>Your Account is Pending. You can access your account after approved by admin</p>
          <div className="text-center">
            <button className='mt-8 btn btn-primary' onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PagePending
