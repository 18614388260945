import React, { useContext, useEffect, useReducer } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useNest } from '../nest/useNest'
import { useClient } from './ClientContext'

const AuthContext = React.createContext()

export function useAuth () {
  return useContext(AuthContext)
}

const initialState = {
  user: {
    displayName: 'Loading...'
  },
  roles: ['user'],
  status: 'loading',
  token: '',
  logged: false
}

function reducer (state, action) {
  switch (action.type) {
    case 'login': {
      return {
        ...state,
        user: action.user,
        status: action.status,
        token: action.token,
        roles: action.roles,
        logged: true
      }
    }
    case 'updateProfile': {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.updated
        },
        status: action.status
      }
    }
    case 'updateBalance': {
      return {
        ...state,
        user: {
          ...state.user,
          currentAmount: action.data.currentAmount,
          totalWithdrawalAmount: action.data.totalWithdrawalAmount,
        },
        status: action.status
      }
    }
    case 'authError': {
      // localStorage.removeItem('token')
      return {
        ...state,
        token: '',
        status: action.status
      }
    }
    case 'logout': {
      return {
        ...initialState,
        status: 'idle'
      }
    }
    default:
      return state
  }
}

export function AuthProvider ({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const location = useLocation()
  const { removeToken, updateToken, getToken, backend } = useNest()
  const { socket } = useClient()
  const navigate = useNavigate()
  const loader = true

  useEffect(() => {
    if (socket?.id) {
      socket.on('userUpdate', data => {
        dispatch({ type: 'updateProfile', updated: data })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket?.id])
console.log('location',location)
  useEffect(() => {
    const token = getToken()
    if (token !== null) {
      backend()
        .get('/auth/profile')
        .then(({ user, roles }) => {
          dispatch({
            type: 'login',
            user: user,
            token: token,
            roles: roles,
            status: 'idle'
          })
          // dispatch({
          //   type: "business",
          //   items: user.business,
          //   status: "idle"
          // })
          navigate(location.pathname)
        })
        .catch(() => {
          dispatch({
            type: 'authError',
            status: 'idle'
          })
          if (window.location.href.search('auth/') === -1) {
            navigate('/auth/login')
          }
        })
    } else {
      dispatch({
        type: 'authError',
        status: 'idle'
      })
      if (window.location.href.search('auth/') === -1) {
        navigate('/auth/login')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * @param {*} email
   * @param {*} password
   * @param {*} profile
   * @returns
   */
  const signup = (email, password, profile) =>
    new Promise((resolve, reject) => {
      let config = {
        loader,
        msg: { loading: 'Registering...', success: 'Registered successfully.' }
      }
      backend()
        .post('/auth/signup', { email, password, profile }, config)
        .then(({ user, token }) => {
          dispatch({
            type: 'login',
            user: user,
            token: token,
            status: 'idle'
          })
          updateToken(token, false)
          return resolve(user)
        })
        .catch(error => {
          reject(error)
        })
    })

  /**
   * @param {*} email
   * @param {*} password
   * @returns Promise<user>
   */
  const login = (email, password, remember = false) =>
    new Promise(resolve => {
      let config = {
        loader,
        msg: {
          loading: 'Authenticating...',
          success: 'Logged in successfully.'
        }
      }
      backend()
        .post('/auth/login', { email, password }, config)
        .then(({ user, roles, token }) => {
          dispatch({
            type: 'login',
            user: user,
            token: token,
            roles: roles,
            status: 'idle'
          })
          updateToken(token, remember)
          return resolve(user)
        })
    })

  const getUserProfile = () =>
    new Promise(resolve => {
      // let config = {
      //   loader,
      //   msg: { loading: 'Retrieving...' }
      // }
      backend()
        .get('/auth/profile')
        .then(({ user, token }) => {
          dispatch({
            type: 'login',
            user: user,
            token: token,
            status: 'idle'
          })
          return resolve()
        })
    })

  const logout = () =>
    new Promise(resolve => {
      let config = {
        loader,
        msg: { loading: 'Requesting...', success: 'Logged out successfully.' }
      }
      backend()
        .post('/auth/logout', {}, config)
        .then(() => {
          removeToken()
          dispatch({ type: 'logout' })
          return resolve()
        })
    })

  const forgotPassword = email =>
    new Promise(resolve => {
      let config = {
        loader,
        msg: { loading: 'Requesting...', success: 'Password Reset Email Sent!' }
      }
      const url =
        window.location.protocol + '//' + window.location.host + '/auth/reset'
      backend()
        .post('/auth/forgot', { email, url }, config)
        .then(() => {
          return resolve()
        })
    })

  const resetPassword = (newPassword, passwordResetCode) =>
    new Promise(resolve => {
      let config = {
        loader,
        msg: { loading: 'Updating...', success: 'Password Updated.' }
      }
      backend()
        .put('/auth/reset', { newPassword, passwordResetCode }, config)
        .then(() => {
          return resolve()
        })
    })

  const updatePassword = payload =>
    new Promise(resolve => {
      let config = {
        loader,
        msg: { loading: 'Updating...', success: 'Password Updated.' }
      }
      backend()
        .put('/auth/password', payload, config)
        .then(() => {
          return resolve()
        })
    })

  const updateUserProfile = payload =>
    new Promise(resolve => {
      let config = {
        loader,
        msg: { loading: 'Updating...', success: 'Profile Updated.' }
      }
      backend()
        .post('/auth/profile', { ...payload }, config)
        .then(data => {
          dispatch({
            type: 'updateProfile',
            updated: payload,
            status: 'idle'
          })
          return resolve()
        })
    })

  const updateBalance = data => {
    dispatch({
      type: 'updateBalance',
      data: data,
    })
  }

  console.log('AuthContext', state)

  return (
    <AuthContext.Provider
      value={{
        ...state,
        dispatch,
        signup,
        login,
        logout,
        getUserProfile,
        updatePassword,
        forgotPassword,
        resetPassword,
        updateUserProfile,
        updateBalance
      }}
    >
      {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
      {children}
    </AuthContext.Provider>
  )
}
