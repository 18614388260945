
export const tradingPercentage = (amount, closingAmount) => {
  const per = closingAmount - amount
  return ((per / amount) * 100).toFixed(2)
}

export const amountByPercentage = (amount, per = 0) => {
  if (per === 0) {
    return amount
  }
  return amount + (per * amount) / 100
}

export const percentageOfAmount = (amount, per = 0) => {
  if (per === 0) {
    return amount
  }
  return amount * (per / 100)
}

export const calculateProfitShare = (profit, share = 100) => {
  if (share === 100) {
    return profit
  }
  return (profit * share) / 100
}

export const calculateProfitLossShare = (
  amount,
  profitShare = 100,
  lossShare = 100,
) => {
  if (amount > 0) {
    if (profitShare === 100) {
      return amount;
    }
    return (amount * profitShare) / 100;
  } else {
    if (lossShare === 100) {
      return amount;
    }
    return (amount * lossShare) / 100;
  }
};

export const formatTxnNumber = (txnNumber) => {
  return String(txnNumber).padStart(4, '0')
}

export const calculatedTotalDepositAmount = (depositAmount, usdToAedCharges, depositFee) => {
  if(depositAmount > 0) {
    const total = (depositAmount * usdToAedCharges) + depositFee;
    return total.toFixed(2)
  }
  return 
}

export const calculatedTotalWithdrawalAmount = (depositAmount, usdToAedCharges, withdrawalFees) => {
  if(depositAmount > 0) {
    const total = (depositAmount * usdToAedCharges) - withdrawalFees;
    return total.toFixed(2)
  }
  return 
}