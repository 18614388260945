import React, { useEffect, useState } from 'react'
import {
  Form,
  Input,
  // Select,
  Submit
} from '../tui'
import { useTransactions } from '../nest/useTransactions'
import { calculatedTotalDepositAmount, formatTxnNumber } from "../utils/calculations"
import ConfirmationModal from '../components/ConfirmationModal'
import { LoaderTextBlock } from '../utils/loader'

const Deposit = () => {
  const [txnId, setTxnId] = useState()
  const { makeDeposit, getOptions } = useTransactions()
  const [totalAmount, setTotalAmount] = useState();
  const [depositAmount, setDepositAmount] = useState();
  const [openConfirmationModal, setConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [extraCharges, setExtraCharges] = useState({
    depositFees:0,
    AED:0
  })

  useEffect(()=>{
    getOptions().then(item => {
      const options = item.items
      const depositFee = options.find((op)=> op.name === "depositFees").value;
      const AEDCharge = options.find((op)=> op.name === "AED").value;
      setExtraCharges({
        depositFees:depositFee,
        AED:AEDCharge
      })
      setIsLoading(false)
    })
  },[])


  const onSubmit = data => {
    setConfirmationModal(true)
  }

  const handleChange =(e) =>{
    const depositValue = e.target.value.replace(/[^0-9]/g, '')
    setDepositAmount(depositValue)
    const total = calculatedTotalDepositAmount(depositValue, extraCharges.AED,extraCharges.depositFees )
    setTotalAmount(total ? total :'')
  }
  const handleConfirmTransaction = () =>{
    makeDeposit({ amount: depositAmount, type: 'Deposit' }).then(item => {
      setTxnId(item.txnId)
      setConfirmationModal(false)
    })
  }

  return (
    <>
      {isLoading ? <LoaderTextBlock /> :
    <div>
      <h1 className='text-xl md:text-3xl mb-4'>Deposit</h1>
      <Form onFinish={onSubmit}>
        <div className=''>
          <div className=''>
            <div className='align-middle mx-auto'>
              <Input.Number
                name='amount'
                placeholder='Enter Amount to Deposit'
                className='p-3'
                min={10}
                rules={{
                  required: "Please Enter Amount"
                }}
                handleChange={handleChange}
              />
              <Input.Email
                name="deposit_fee"
                defaultValue={`Deposit Fees: ${extraCharges.depositFees}`}
                className='p-3'
                min={10}
                disabled
              />
              <Input.Email
                name="usd_to_aed"
                defaultValue={`USD to AED rate: ${extraCharges.AED}`}
                className='p-3'
                min={10}
                disabled
              />
              <Input.Email
                label="Your final deposit amount"
                name="total_amt"
                defaultValue={`AED ${totalAmount ? totalAmount : ''}`}
                className='p-3'
                min={10}
                disabled
              />
              <Submit className='btn btn-primary w-full'>Deposit</Submit>
            </div>
          </div>
        </div>
      </Form>
      <div className='mx-auto text-center my-8'>
      <div className=''>
          Please make payment on following Bank Account:
            <p>Account Holder Name: Hemant Kumar</p>
            <p>Bank Name: Emirates NBAD Bank</p>
            <p>Account IBAN: AE370260001015614228001</p>
            <p>Account No: 1015614228001</p>
            <p>IFSC Code: ENBAD</p>
            <p>Remarks: Personal</p>
            {/* <p>
              This is your reference transaction id <b>{formatTxnNumber(txnId)}</b>
            </p> */}
          </div>
        </div>
      {txnId && (
        <div className='mx-auto text-center my-8'>
          <h2 className='text-2xl'>
          Thanks, as soon as we receive and transfer this amount to exchange, it will be reflected on Wallet Page. Please allow 1-3 days owing to fund transfer time
          </h2>
          <div className=''>
          {/* Please make payment on following Bank Account:
            <p>Account Holder Name: Hemant Kumar</p>
            <p>Bank Name: Emirates NBAD Bank</p>
            <p>Account IBAN: AE370260001015614228001</p>
            <p>Account No: 1015614228001</p>
            <p>IFSC Code: ENBAD</p>
            <p>Remarks: Personal</p> */}
            <p>
              This is your reference transaction id <b>{formatTxnNumber(txnId)}</b>
            </p>
          </div>
        </div>
      )}
      {openConfirmationModal && <ConfirmationModal
        open={openConfirmationModal}
        handleClose={()=>setConfirmationModal(false)}
        onCLickConfirm={handleConfirmTransaction}
        status="Confirm"
      />}
    </div>}
    </>
  )
}

export default Deposit
