const initialState = {
  transactions: {
    status: "loading",
    items: []
  }
}

const transactions = (state = initialState, action) => {
  switch (action.type) {
    case "transactions": {
      return {
        ...state,
        transactions: {
          items: action.items,
          status: action.status
        }
      }
    }
    case "transactionAdd": {
      state.transactions.items.push(action.item)
      return {
        ...state,
        transactions: {
          items: state.transactions.items.sort((a, b) => a.createdAt > b.createdAt),
          status: 'loaded'
        }
      }
    }
    case "transactionRemove": {
      // const tempItems = state.transactions.items.filter((itm) => itm.id !== action.id)
      const tempItems = [...state.transactions.items]
      const index = tempItems.findIndex((itm) => itm.id === action.id)
      tempItems[index].status = 'Cancelled'
      // const tempItems = state.transactions.items.find((itm) => itm.id !== action.id).status = 'Rejected'
      return {
        ...state,
        transactions: {
          items: tempItems.sort((a, b) => a.createdAt > b.createdAt),
          status: 'loaded'
        }
      }
    }
    case "transactionsLoading": {
      return {
        ...state,
        transactions: {
          ...state.transactions,
          status: 'loading'
        }
      }
    }
    case "transactionsError": {
      return {
        ...state,
        transactions: {
          items: state.transactions.items || [],
          status: 'error'
        }
      }
    }
    default:
      return state
  }
}

export default transactions
