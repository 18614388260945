import React, { useContext, useEffect, useReducer, useState } from "react"
import io from 'socket.io-client'
import { config } from "../config"

const ClientContext = React.createContext()

export function useClient() {
  return useContext(ClientContext)
}

const initialState = {
  webp: true
}

function reducer(state, action) {
  switch (action.type) {
    case "webp": {
      return {
        ...state,
        webp: action.value
      }
    }
    default:
      return state
  }
}

export function ClientProvider({ children }) {
  const [socket, setSocket] = useState(null)
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const newSocket = io(config.apiUrl)
    newSocket.on("connect", (id) => {
      // newSocket.emit('uid', { uid: newSocket.id })
      // newSocket.emit('events', { test: 'test' })
      // newSocket.emit('identity', 0, response =>
      //   console.log('Identity:', response),
      // )
      console.log("connected", newSocket.id)
    })
    newSocket.on("disconnect", () => {
      console.log('Disconnected')
    })
    setSocket(newSocket)
    return () => newSocket.close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const imgs = (supports) => {
      console.log("webp supports", supports)
      dispatch({
        type: "webp",
        value: supports
      })
    }

    function check_webp(callback, feature = "alpha") {
      var kTestImages = {
        lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
        lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
        alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
        animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
      }
      var img = new Image()
      img.onload = function () {
        var result = (img.width > 0) && (img.height > 0)
        callback(result)
      }
      img.onerror = function () {
        callback(false)
      }
      img.src = "data:image/webp;base64," + kTestImages[feature]
    }
    check_webp(imgs)
    // axios.get("http://ip-api.com/json")
    // axios.get("https://api.ipify.org?format=json")
    //   .then(res => {
    //     console.log(res.data)
    //   }).catch(error => {
    //     console.log(error)
    //   })
  }, [])

  const preferWebp = (url, fallback = "png") => {
    return state?.webp ? url + '.webp' : url + '.' + fallback
  }

  // console.log("ClientContext", state)

  return (
    <ClientContext.Provider value={{
      ...state,
      dispatch,
      preferWebp,
      socket
    }}>
      {children}
    </ClientContext.Provider>
  )
}
