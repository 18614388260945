import React from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../context/AuthContext"
import { Form, Input, Submit } from "../tui"

const AccountSetting = () => {
  const navigate = useNavigate()
  const { user, updateUserProfile } = useAuth()

  const onSubmit = data => {
    updateUserProfile(data).then(() => {
      navigate("/profile")
    })
  }

  return (
    <div>
      <h1 className="text-3xl mb-4">Personal Details</h1>
      <div className="w-full col-span-2">
        <div className="text-lg">Edit Address</div>
      </div>
      <Form onFinish={onSubmit} initialValues={user}>
        <div className="grid md:grid-cols-2">
          <div className="grid md:grid-cols-2 gap-5 mt-5">
            <div className="w-full">
              <Input.Text
                name="firstName"
                required
                label="First Name"
                placeholder="First Name"
                rules={{ required: "First Name is required" }}
              />
            </div>
            <div className="w-full">
              <Input.Text
                name="lastName"
                required
                label="Last Name"
                placeholder="Last Name"
                rules={{ required: "Last Name is required" }}
              />
            </div>
          </div>
          {/* <div>
            <Link to="/account/password">Change Password</Link>
          </div> */}
        </div>
        <div className="md:w-1/2">
          <div className="grid grid-cols-1 gap-5 mt-5">
            <div className="w-full">
              <Input.Text
                name="mobile"
                required
                label="Contact No"
                placeholder="Contact No"
                rules={{ required: "Contact No is required" }}
              />
            </div>
            <div className="w-full">
              <Input.Text
                name="bankAccount"
                required
                label="Bank Name & Branch"
                placeholder="Bank Name & Branch"
                rules={{ required: "Bank Name & Branch is required" }}
              />
            </div>
            <div className="w-full">
              <Input.Text
                name="accountIBAN"
                required
                label="Account IBAN"
                placeholder="Account IBAN"
                rules={{ required: "Account IBAN is required" }}
              />
            </div>
            <div className="w-full">
              <Input.Text
                name="accountNo"
                required
                label="Account No"
                placeholder="Account No"
                rules={{ required: "Account No is required" }}
              />
            </div>
            <div className="w-full">
              <Input.Text
                name="ifsc"
                required
                label="IFSC Code"
                placeholder="IFSC Code"
                rules={{ required: "IFSC Code is required" }}
              />
            </div>
          </div>
        </div>
        <div className="md:w-1/2">
          <div className="flex justify-end mt-5">
            <Submit className="btn btn-primary">
              Submit
            </Submit>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default AccountSetting
