const env = process.env.REACT_APP_ENV || 'uat'
const WEBSITE_NAME = process.env.REACT_APP_SITE_NAME || 'REACT APP'

const prodEnv = {
  websiteName: WEBSITE_NAME,
  description: 'Gold Mining & Trading are analogous',
  apiUrl: 'https://api.49ers.trading',
  env: 'prod',
}

const uatEnv = {
  websiteName: `UAT ${WEBSITE_NAME}`,
  description: 'Gold Mining & Trading are analogous',
  apiUrl: 'https://api.49ers.trading',
  // apiUrl: 'https://uat.49ers.trading',
  // apiUrl: 'http://13.37.184.223:2000/',
  // apiUrl: 'http://localhost:2000',
  env: 'uat',
}

const config = env === 'uat' ? uatEnv : prodEnv

export {
  config
}