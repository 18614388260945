import React from "react"
import { BrowserRouter } from "react-router-dom"
import { ToastContainer } from "react-toastify"
// import './App.css'
import Loader, { LoaderSuspense } from "./utils/loader"
import { AuthProvider } from "./context/AuthContext"
import { ClientProvider } from "./context/ClientContext"
import AllRoutes from "./Routes"
// import UatHighLight from "./components/UatHighLight"

function App() {
  return (
    <React.Suspense fallback={<LoaderSuspense />}>
      {/* <UatHighLight /> */}
      <BrowserRouter>
        <ClientProvider>
          <AuthProvider>
            <AllRoutes />
          </AuthProvider>
        </ClientProvider>
      </BrowserRouter>
      <Loader />
      <ToastContainer hideProgressBar={true} theme="colored" position="bottom-left" />
    </React.Suspense>
  )
}

export default App
