export const getBgColor = (status) => {
  switch (status) {
    case 'Approved':
    case "Success":
    case "Confirmed":
      return "bg-success"
    case "Processing":
      return "bg-info"
    case "Pending":
      return "bg-warning"
    case "Failed":
    case "Expired":
    case "Rejected":
      return "bg-danger"
      case "Cancelled":
        return "bg-orange-600"
    default:
      return "bg-info"
  }
}


// export const getLabel = (label) => {
//   switch (label) {
//     case "Up to Date":
//       return "Up to Date"
//     case "Past Due":
//       return "Past Due"
//     case "Due Soon":
//       return "Due Soon"
//     default:
//       return "Up to Date"
//   }
// }

export const getBadge = status => {
  switch (status) {
    case 'Approved': return 'success'
    case 'Deposit': return 'success'
    case 'Withdrawal': return 'danger'
    case 'Profit': return 'success'
    case 'Loss': return 'danger'
    case 'Confirmed': return 'success'
    case 'Active': return 'success'
    case 'Success': return 'success'
    case 'Inactive': return 'secondary'
    case 'Awaiting': return 'warning'
    case 'Pending': return 'warning'
    case 'Blocked': return 'danger'
    case 'Failed': return 'danger'
    case 'Canceled': return 'danger'
    case 'Expired': return 'danger'
    case 'Disabled': return 'danger'
    default: return 'primary'
  }
}

export const getLabel = status => {
  switch (status) {
    case 'Awaiting': return 'Awaiting Payment'
    case 'Canceled': return 'Canceled'
    case 'Confirmed': return 'Confirmed'
    case 'Expired': return 'Expired'
    case 'Failed': return 'Failed'
    case 'Pending': return 'Pending'
    case 'Processing': return 'Processing'
    case 'Success': return 'Success'
    default: return 'NA'
  }
}
